@import 'styles/theme.scss';

.locationSubTitle {
  display: block;
  font-size: small;
}

.divider {
  display: flex;
  width: 100%;
  border-bottom: 1px solid $grey1;
}
