@import './theme';

// blue background page heading
@mixin pageHeading {
  font-weight: $font-weight-700;
  font-size: 24px;
  letter-spacing: $letter-spacing-heading;
  line-height: 34px;
  margin: $spacing-m auto $spacing-m;
  position: relative;
  color: $white;
  max-width: 750px;
  text-align: center;

  @media (min-width: $md) {
    font-size: 38px;
    line-height: 48px;
    margin: 28px auto;
    text-align: center;
  }
}
