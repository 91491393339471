@import 'styles/theme';

.container {
  background: $white;
  padding: 16px 16px 16px;
  margin: 0 0 16px;
  border-radius: 4px;

  @media (min-width: $sm) {
    border-radius: 14px;
    box-shadow: 0 4px 4px $shadow1;
    padding: 16px 24px 24px;
    margin: 0 auto 16px;
    max-width: 960px;
  }

  @media (min-width: $md) {
    margin: 0 auto 48px;
  }

  .gridContainer {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    column-gap: 8px;
    row-gap: 8px;
    align-items: end;
    grid-template-areas:
      'grade'
      'location'
      'submit';

    @media (min-width: $sm) {
      column-gap: 24px;
      row-gap: 16px;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      grid-template-areas: 'grade grade location location submit';
    }

    @media (min-width: $md) {
      column-gap: 28px;
      row-gap: 8px;
    }
  }

  .gridItemGrade {
    grid-area: grade;
  }
  .gridItemLocation {
    grid-area: location;
  }
  .gridItemSubmit {
    grid-area: submit;
    padding-top: 8px;
  }
}
