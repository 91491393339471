@import 'styles/theme.scss';

.homePageCvpContainer {
  margin: 128px auto;
  width: 924px;
  text-align: center;
  @media (max-width: $md) {
    width: 100%;
    margin: 64px auto;
    padding: 0 24px;
  }

  .homePageCvpContainerBg {
    padding: 56px 76px;
    border-radius: 6px;
    background-color: $primary-50;
    @media (max-width: $md) {
      padding: 24px 16px;
    }
  }

  .title {
    color: $primary-dark !important;
  }

  .cvpTextContainer {
    padding: 8px 0;
  }

  .cvpText {
    color: black;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  .button {
    background-color: #247eff !important;
  }
}
