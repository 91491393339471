@import 'styles/theme.scss';

.container {
  :global {
    .Mui-disabled {
      .MuiInput-input {
        color: $grey3;
      }
    }
  }
}
