@import 'styles/theme';
@import 'styles/common';

.container {
  position: relative;
  padding: $spacing-s $spacing-m 0;
  border-bottom: 1px solid $white;

  .heading {
    @include pageHeading();
  }

  .form {
    position: relative;
  }
}
